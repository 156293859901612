var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex cell-renderer-property-checkbox-container" },
    [
      _c("vs-checkbox", {
        attrs: { disabled: "" },
        model: {
          value: _vm.params.data.active,
          callback: function ($$v) {
            _vm.$set(_vm.params.data, "active", $$v)
          },
          expression: "params.data.active",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }